import $ from 'jquery';

$(document).ready(function() {
		
	//HEADER

	$(window).scroll(function() {
		var sticky = $('header'),
		scroll = $(window).scrollTop();
	
		if (scroll >= 100) { 
			sticky.addClass('fixed'); 
		} else { 
			sticky.removeClass('fixed');
			
		}
	});
	
	//HAMBURGER
	
	$('.hamburger > div').click(function(){
		$('#mySidenav').css('width', '25rem');
	});
		
	$('#mySidenav .close').click(function(){
		$('#mySidenav').css('width', '0');
	});

});